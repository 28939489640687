import { useContext } from 'react'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'

import { RecommendationContext } from '../../contexts/recommendations-context'
import type {
  DataRecommendation,
  Showcases,
} from '../../types/linx-recommendations'
import ShelfV2 from '../ShelfV2/ShelfV2'

type GenericShelfV2Props = {
  position: keyof typeof POSITION_MAP
  enableSession?: boolean
}

const POSITION_MAP = {
  topo: 'top',
  top: 'top',
  middle: 'middle',
  meio: 'middle',
  baixo: 'bottom',
  bottom: 'bottom',
  'area bonus': 'area-bonus',
  'area-bonus': 'area-bonus',
}

const GenericShelfv2 = ({
  position,
  enableSession = true,
}: GenericShelfV2Props) => {
  const { dataRecommendation, error, loading } = useContext(
    RecommendationContext
  )

  if (!enableSession || !dataRecommendation || error) {
    return null
  }

  const shelfData = dataRecommendation.filter((item: DataRecommendation) => {
    return item.position === POSITION_MAP[position] && item.showcases.length
  })

  const showCases = shelfData[0]?.showcases ?? []

  return loading ? (
    <div className="w-full max-w-[1280px] mx-auto">
      <ProductShelfSkeleton />
    </div>
  ) : (
    <div>
      {showCases.map(
        ({ id, impressionUrl, title, name, displays }: Showcases) => {
          return (
            <ShelfV2
              engine="linx"
              key={id}
              {...{ impressionUrl, title, name }}
              products={displays[0].recommendations}
            />
          )
        }
      )}
    </div>
  )
}

export default GenericShelfv2
