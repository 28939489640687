import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { getCookie } from 'src/utils/getCookie'
import Icon from 'src/components/ui/Icon'
import { useMobile } from 'src/hooks/useMobile'
import { useState } from 'react'
import { SlideOverDefault } from 'src/components/ui/SlideOverDefault/SlideOverDefault'
import { Statsig, useExperiment } from 'statsig-react'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import storeConfig from '../../../../../store.config'
import GoToShippingSkeleton from './GoToShippingSkeleton'

const GoToShipping = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const { isLoading, orderForm, addMarketingData } = useCheckout()
  const { isMobile } = useMobile()
  const userDataCookie = getCookie('memberAuthToken')

  const checkoutUrl = `${storeConfig.checkoutUrl}?orderFormId=${orderForm.orderFormId}/#shipping`

  const loginUrl = `${storeConfig.loginUrl}?returnUrl=${encodeURIComponent(
    checkoutUrl
  )}`

  const { config } = useExperiment('guest_checkout')

  const guestCheckoutModal = config?.value?.guest_checkout_modal

  const isGuestCheckout = !!guestCheckoutModal && !userDataCookie

  const linkContinueWithPurchase = userDataCookie ? checkoutUrl : loginUrl

  const disableButton = orderForm.items.some(
    (item) => item.availability !== 'available'
  )

  function trackEvent(
    eventAction: string,
    eventPage: string,
    eventCategory: string
  ) {
    const event = makeEventTrack({
      eventAction,
      eventPage,
      eventCategory,
    })

    sendEvent(event)
  }

  function sendStatsigEvent(label: string) {
    Statsig.logEvent('direcionar_login', null, {
      device: isMobile ? 'mobile' : 'desktop',
      label,
    })
  }

  const handleSubmit = async () => {
    if (!isGuestCheckout) {
      sendStatsigEvent('login_united')
      await addMarketingData({
        utmMedium: 'site',
        utmSource: isMobile ? 'mobile' : 'desktop',
      })

      window.location.href = linkContinueWithPurchase

      return
    }

    setIsSideMenuOpen(true)
    trackEvent('Continuar para entrega', 'carrinho', 'clique')
  }

  if (isLoading && !isMobile) {
    return <GoToShippingSkeleton />
  }

  return (
    <>
      <div className="sm:my-8 fixed sm:relative left-0 bottom-0 sm:left-auto sm:bottom-auto p-4 sm:p-0 w-full bg-[#fff] z-20 shadow-dropCard sm:shadow-none">
        <div className="flex items-center sm:hidden mb-[14px] ">
          <span className="text-xl font-medium text-restructure-primary">
            Total: {orderForm.totalizers.total}
          </span>
        </div>
        <button
          className={`h-[48px] w-full mb-7 sm:mb-0 sm:h-[64px] flex bg-restructure-background-action-1 text-restructure-primary-inverted rounded-round justify-center items-center ${
            disableButton
              ? 'pointer-events-none opacity-50'
              : 'pointer-events-auto'
          }`}
          onClick={handleSubmit}
        >
          <span className="desktop-body-semibold-text1 mr-2 font-inter text-base sm:text-lg font-semibold">
            Continuar para entrega
          </span>
          <Icon width={24} height={24} name="chevron-right-white" />
        </button>
      </div>

      <SlideOverDefault
        position={isMobile ? 'bottom' : 'right'}
        isOpen={isSideMenuOpen}
        onClose={() => setIsSideMenuOpen(false)}
      >
        <div className="px-8 sm:px-12 flex flex-col gap-10 font-inter text-restructure-primary sm:gap-[72px]">
          <div>
            <span className="text-2xl font-semibold">
              Faça login ou crie uma conta
            </span>
            <p className="mt-2 text-base">
              Ao criar uma conta você se torna membro clube Decathlon de forma
              gratuita e tem acesso a benefícios exclusivos!
            </p>
            <button
              className="text-white w-full font-semibold w-ful py-3 bg-restructure-background-action-1 rounded-full mt-8 sm:py-[18.5px]"
              onClick={async () => {
                sendStatsigEvent('login_united')

                await addMarketingData({
                  utmMedium: 'site',
                  utmSource: isMobile ? 'mobile' : 'desktop',
                })

                window.location.href = loginUrl
              }}
            >
              Fazer login
            </button>
          </div>
          <div>
            <span className="text-2xl font-semibold mb-2">Compra rápida</span>
            <p className="mt-2 text-base">
              Compre sem a necessidade de criação de conta
            </p>

            <button
              className="mt-8 w-full font-semibold py-3 rounded-full border border-lightGray sm:py-[18.5px]"
              onClick={async () => {
                sendStatsigEvent('guest_checkout')

                await addMarketingData({
                  utmMedium: 'site',
                  utmSource: isMobile ? 'mobile' : 'desktop',
                })

                window.location.href = checkoutUrl
              }}
            >
              Continuar
            </button>
          </div>
        </div>
      </SlideOverDefault>
    </>
  )
}

export default GoToShipping
