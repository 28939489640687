import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import type { OrderForm } from 'src/sdk/checkout/entity/orderForm'

import type { CartViewEvent } from './types/cartViewEvent'

const parseStringToNumber = (value: string) => {
  const parsedValue = value
    ?.replace('R$', '')
    .replace(' ', '')
    .replace(',', '.')

  return parseFloat(parsedValue)
}

export const makeCartViewEvent = (
  orderForm: OrderForm,
  eventName: string
): CartViewEvent => {
  const {
    clientProfileData,
    items,
    orderFormId,
    loggedIn,
    shippingData,
    clientPreferencesData,
    totalizers,
    paymentData,
  } = orderForm

  const isB2B = checkEnviromentIsB2B()

  const productsV1 = items.map((e) => {
    return {
      id: e.id,
      name: e.name,
      category: e.category,
      brand: e.brand,
      variant: e.skuName,
      price: parseStringToNumber(e.price),
      quantity: e.quantity,
    }
  })

  const productsV2 = items.map((e) => {
    return {
      id: e.productId,
      variant: e.id,
      name: e.productName,
      category: e.category,
      brand: e.brand,
      price: parseStringToNumber(e.price),
      quantity: e.quantity,
      dimension1: e.productRefId,
      dimension2: e.refId,
      dimension3: e.skuName,
    }
  })

  const orderFormParsedProducts = items.map((e) => {
    return {
      id: e.productId,
      name: e.name,
      sku: e.id,
      skuName: e.skuName,
      seller: e.sellerName,
      sellerId: e.seller,
      brand: e.brand,
      brandId: e.brandId,
      isGift: e.isGift,
      category: e.categoryName,
      categoryId: e.categoryId,
      categoryTree: e.categoryTree,
      categoryIdTree: e.categoryIdTree,
      originalPrice: parseStringToNumber(e.price),
      price: parseStringToNumber(e.sellingPrice),
      sellingPrice: parseStringToNumber(e.sellingPrice),
      tax: parseStringToNumber(e.tax),
      quantity: e.quantity,
      components: e.components,
      measurementUnit: e.measurementUnit,
      unitMultiplier: e.unitMultiplier,
    }
  })

  const visitorContactInfo = [
    clientProfileData?.email,
    clientProfileData?.firstName,
    clientProfileData?.lastName,
  ]

  const userAddress = `${shippingData?.address?.street}, ${shippingData?.address?.number} - ${shippingData?.address?.complement} - ${shippingData?.address?.neighborhood} - ${shippingData?.address?.city} - ${shippingData?.address?.state}`

  const shippingMethod = shippingData?.logisticsInfo
    ?.filter((e: any) => e.selectedSla !== null)
    .map((e) => e.selectedSla)

  const paymentType =
    paymentData?.availableAccounts && paymentData?.availableAccounts?.length > 0
      ? paymentData?.availableAccounts[0]?.paymentSystemName
      : null

  return {
    event: eventName,
    orderFormId,
    salesChannel: isB2B
      ? B2B.salesChannel.toString()
      : B2C.salesChannel.toString(),
    campaignName: null,
    campaignSource: null,
    campaignMedium: null,
    internalCampaignName: null,
    internalCampaignPart: null,
    originalLocation: window.location.href,
    originalReferrer: window.document.referrer,
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
        },
        products: productsV1,
      },
    },
    ecommerceV2: {
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
          },
          products: productsV2,
        },
      },
    },
    visitorType:
      clientProfileData !== null ? 'existing customer' : 'new customer',
    visitorLoginState:
      loggedIn === true ? 'authenticated customer' : 'unauthenticated customer',
    visitorContactInfo: clientProfileData !== null ? visitorContactInfo : [],
    visitorDemographicInfo: shippingData?.address
      ? [shippingData?.address?.postalCode, userAddress]
      : [],
    visitorOptinNewsLetter: clientPreferencesData?.optinNewsLetter ?? false,
    orderFormTax: null,
    orderFormShipping: totalizers?.shipping
      ? parseStringToNumber(totalizers?.shipping)
      : null,
    orderFormShippingMethod: shippingMethod,
    orderFormPromoCode: null,
    orderFormPaymentType: paymentType,
    orderFormTotal: parseStringToNumber(totalizers?.total),
    orderFormProducts: orderFormParsedProducts,
  }
}
