import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'

type CashbackInformationModalProps = {
  setCashbackInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CashbackInformationModal = ({
  setCashbackInfoModalOpen,
}: CashbackInformationModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    const eventTarget = event.target as Node | null

    if (modalRef.current === eventTarget) {
      setCashbackInfoModalOpen(false)
    }
  }

  useEffect(() => {
    if (!document) {
      return () => {}
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return createPortal(
    <div
      className="cashback-info-modal-backdrop bg-[#15181bcc] fixed z-10 top-0 left-0 w-full h-full flex items-center justify-center"
      ref={modalRef}
    >
      <div className="bg-[#fff] w-full max-w-[347px] text-sm font-inter pt-[48px] px-[16px] pb-[32px]  relative flex flex-col items-center justify-center rounded-md">
        <strong className="max-w-[251px] text-center mb-[32px]">
          Você sabia que os clientes do Clube Decathlon recebem cashback?
        </strong>

        <p className="max-w-[251px] text-center mb-[32px]">
          Comprando usando sua conta Decathlon, o valor que você receberá nesta
          compra será automaticamente adicionado ao seu saldo de cashback (após
          24 horas úteis da emissão da nota fiscal), tornando-se disponível para
          ser usado em sua próxima compra.
        </p>

        <p className="max-w-[251px] text-center mb-[32px]">
          <strong className="max-w-[251px] text-center">
            Deseja utilizar seu saldo disponível?
          </strong>
          <br />
          Se você já possui saldo acumulado e deseja usá-lo nesta compra, basta
          fechar seu pedido com sua conta Decathlon e incluí-lo na etapa de
          pagamento.
        </p>

        <button
          className="absolute right-[16px] top-[26px]"
          onClick={() => setCashbackInfoModalOpen(false)}
        >
          <CloseButtonIcon />
        </button>
      </div>
    </div>,
    document.body
  )
}

export default CashbackInformationModal
