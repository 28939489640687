import { useEffect, useState } from 'react'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { SELLER_DECATHLON } from 'src/constants'
import { InformationIcon } from 'src/components/Icons/InformationIcon'

import CashbackInformationModal from './CashbackInformationModal'

const BASE_CASHBACK_URL =
  'https://decathlonstore.myvtex.com/app/pub/cashback/orderFormId'

export const CashbackCheckout = () => {
  const { orderForm } = useCheckout()
  const [cashbackValue, setCashbackValue] = useState('R$ 00,00')
  const [cashbackInfoModalOpen, setCashbackInfoModalOpen] = useState(false)

  useEffect(() => {
    const updateCashbackValue = async () => {
      const isEligible = orderForm.items.some(
        ({ seller }) => seller === SELLER_DECATHLON
      )

      if (!isEligible) {
        setCashbackValue('')

        return
      }

      try {
        const rawCashbackData = await fetch(
          `${BASE_CASHBACK_URL}/${orderForm.orderFormId}`
        )

        const cashbackData = await rawCashbackData.json()

        if (cashbackData.totalizers[0].value < 1) {
          setCashbackValue('')
        }

        setCashbackValue(cashbackData.totalizers[0].valueFormatted)
      } catch (error) {
        console.error(error)
        setCashbackValue('')
      }
    }

    updateCashbackValue()
  }, [orderForm.items, orderForm.orderFormId])

  return cashbackValue ? (
    <div className="sticky top-0 z-10 mt-7 sm:flex sm:top-auto sm:mt-0">
      <button
        className="bg-[#D6FFE3] w-full text-xs font-inter font-medium py-[6px] text-center flex justify-center items-center text-restructure-primary sm:mb-[32px] gap-2 h-[30px]"
        onClick={() => setCashbackInfoModalOpen(true)}
      >
        <p className="text-xs">
          Seja clube e ganhe {cashbackValue} de Cashback com esta compra
        </p>
        <InformationIcon width={16} height={16} color="#101010" />
      </button>
      {cashbackInfoModalOpen && (
        <CashbackInformationModal
          setCashbackInfoModalOpen={setCashbackInfoModalOpen}
        />
      )}
    </div>
  ) : null
}
