import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { SummaryCart } from 'src/components/checkout/cart/SummaryCart'
import { SummaryProducts } from 'src/components/checkout/cart/SummaryProducts'
import { FooterCheckout } from 'src/components/checkout/FooterCheckout'
import { HeaderCheckout } from 'src/components/checkout/HeaderCheckout'
import { RecommendationProvider } from 'src/components/restructure/product/contexts/recommendations-context'
import GenericShelfv2 from 'src/components/restructure/product/shelves/GenericShelfV2'
import { CartEmpty } from 'src/components/checkout/CartEmpty'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { makeCartViewEvent } from 'src/utils/restructure/analytics/makeCartViewEvent'
import { StepsCheckout } from 'src/components/checkout/StepsCheckout'
import { useEffect } from 'react'
import { useLinxCartView } from 'src/sdk/linx/hooks/useLinxCartView'
import { useMobile } from 'src/hooks/useMobile'
import { CashbackCheckout } from 'src/components/checkout/cashbackCheckout'

function CartPage() {
  const { isEmpty, orderForm } = useCheckout()
  const { sendCartViewEvent } = useLinxCartView()
  const { isMobile } = useMobile()

  useEffect(() => {
    const cartViewEvent = makeCartViewEvent(orderForm, 'cart')
    const cartLoadedEvent = makeCartViewEvent(orderForm, 'cartLoaded')

    try {
      if (
        typeof window !== 'undefined' &&
        typeof document === 'object' &&
        orderForm?.orderFormId
      ) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(cartViewEvent)
        window.dataLayer.push(cartLoadedEvent)
      }
    } catch {
      throw new Error('Object for dataLayer is undefined.')
    }
  }, [orderForm])

  useEffect(() => {
    if (!orderForm.items || !orderForm.items.length || !orderForm.orderFormId) {
      return
    }

    sendCartViewEvent({
      id: orderForm?.orderFormId ?? '',
      items: orderForm.items.map(
        ({ productId: pid, price, quantity, id: sku }) => ({
          pid,
          price: Number(price.replace(/\D/g, '')) / 100,
          quantity,
          sku,
        })
      ),
    })
  }, [orderForm.items, orderForm.orderFormId, sendCartViewEvent])

  return (
    <>
      <GatsbySeo title="Carrinho" description="Carrinho" />
      <HeaderCheckout />
      {!isEmpty && <StepsCheckout />}
      {isMobile && <CashbackCheckout />}

      <main className="max-w-[1280px] mx-auto flex flex-col">
        {isEmpty ? (
          <CartEmpty />
        ) : (
          <div className="flex gap-[70px] flex-col mx-4 mt-12 sm:mt-20 sm:mx-0 sm:flex-row z-[1]">
            <SummaryProducts />
            <SummaryCart />
          </div>
        )}

        <RecommendationProvider pageName="cart">
          <GenericShelfv2 enableSession position="top" />
          <GenericShelfv2 enableSession position="middle" />
          <GenericShelfv2 enableSession position="bottom" />
        </RecommendationProvider>
      </main>

      <FooterCheckout />
    </>
  )
}

export default CartPage
