import type { PropsWithChildren } from 'react'
import { useRef } from 'react'
import useClickAway from 'react-use/lib/useClickAway'
import CloseListIcon from 'src/images/icons/CloseListIcon'

import { Overlay } from '../Overlay/Overlay'

interface SlideOverProps {
  position: 'bottom' | 'left' | 'right'
  isOpen: boolean
  onClose: () => void
}

export const SlideOverDefault = ({
  children,
  position,
  isOpen,
  onClose,
}: PropsWithChildren<SlideOverProps>) => {
  const ref = useRef(null)

  useClickAway(ref, () => onClose())

  const transitionClasses = `transition-all duration-300 ease-out ${
    !isOpen &&
    `${position === 'bottom' ? 'translate-y-full' : 'translate-x-full'}`
  }`

  return (
    <div
      className={`${
        isOpen ? 'visible' : 'invisible'
      } fixed top-0 bottom-0 left-0 right-0 z-[9999] inset-0`}
    >
      <Overlay show={isOpen} />
      <div
        ref={ref}
        className={`${
          position === 'bottom' ? 'h-[570px]' : ''
        } ${position}-0 w-full bg-white absolute sm:h-full sm:w-[474px] ${transitionClasses}`}
      >
        <div className="w-full p-7 flex justify-end">
          <button onClick={onClose}>
            <CloseListIcon />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}
